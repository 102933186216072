<template>
  <div>
    <a-card :title="$t('容器拣货')">
      <a-button slot="extra" type="primary" ghost @click="goBack"><a-icon type="left" />{{ $t("返回") }}</a-button>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :loading="tableLoading"
          :columns="columns"
          :data-source="pickingMaterialItems"
          :pagination="false"
          :scroll="{ x: 1560 }"
        >
          <div slot="picking_quantity" slot-scope="value, item, index">
            <a-input-number v-model="item.picking_quantity" :min="0" size="small"></a-input-number>
          </div>
          <template slot="sub_batch" slot-scope="value, item, index">
            <a-select
              v-model="item.sub_batch"
              :placeholder="$t('请输入或选择容器')"
              size="small"
              style="width: 100%"
              allowClear
              @change="selectPallet(index, $event)"
            >
              <a-select-option
                v-for="inventoryItem in item.inventory_items.filter((inventory_item) => inventory_item.pallet_number)"
                :key="inventoryItem.id"
                :value="inventoryItem.id"
              >
                {{ inventoryItem.pallet_number }} | {{ $t("库存") }} {{ inventoryItem.remain_quantity }}
              </a-select-option>
            </a-select>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button type="danger" @click="removeMaterial(item, index)">{{ $t("删除") }}</a-button>
              <a-button type="primary" @click="copyMaterial(item, index)">{{ $t("复制") }}</a-button>
            </a-button-group>
          </div>
        </a-table>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="createLoading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { pickingCreateMaterials, pickingRecordCreate } from "@/api/stockOut";

export default {
  data() {
    return {
      tableLoading: false,
      createLoading: false,
      pickingOrderId: null,
      pickingMaterialItems: [],
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          width: 150,
          fixed: "left",
        },
        {
          title: this.$t("库位"),
          dataIndex: "location_number",
          width: 180,
        },
        {
          title: this.$t("容器"),
          width: 240,
          scopedSlots: { customRender: "sub_batch" },
        },
        {
          title: this.$t("拣货数量"),
          dataIndex: "picking_quantity",
          width: 100,
          scopedSlots: { customRender: "picking_quantity" },
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          width: 180,
        },
        {
          title: this.$t("待拣货数量"),
          dataIndex: "remain_quantity",
          width: 160,
        },
        {
          title: this.$t("批次"),
          dataIndex: "batch_number",
          width: 180,
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
          width: 60,
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          width: 120,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    initData() {
      this.pickingOrderId = this.$route.query.id;
      this.getPickingMaterials();
    },
    goBack() {
      this.$router.go(-1);
    },
    getPickingMaterials() {
      this.tableLoading = true;
      pickingCreateMaterials({ id: this.$route.query.id })
        .then((data) => {
          let pickingMaterialItems = [];
          for (let item of data) {
            if (item.remain_quantity > 0 && item.enable_pallet) {
              pickingMaterialItems.push({
                ...item,
                location_number: undefined,
                picking_quantity: undefined,
              });
            }
          }

          this.pickingMaterialItems = pickingMaterialItems;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    selectPallet(index, value) {
      const pickingMaterialItems = this.pickingMaterialItems;
      const pickingMaterialItem = pickingMaterialItems[index];
      let inventiryIndex = pickingMaterialItem.inventory_items.findIndex((item) => item.id == value);
      if (inventiryIndex === -1) {
        pickingMaterialItem.location_number = undefined;
        pickingMaterialItem.picking_quantity = undefined;
      } else {
        const inventoryItem = pickingMaterialItem.inventory_items[inventiryIndex];
        pickingMaterialItem.location_number = inventoryItem.location_number;
        pickingMaterialItem.picking_quantity =
          pickingMaterialItem.remain_quantity > inventoryItem.remain_quantity
            ? inventoryItem.remain_quantity
            : pickingMaterialItem.remain_quantity;
      }
      this.pickingMaterialItems = [...pickingMaterialItems];
    },
    create() {
      if (this.pickingMaterialItems.length == 0) {
        this.$message.warn(this.$t("没有产品"));
        return;
      }

      for (let item of this.pickingMaterialItems) {
        if (!item.sub_batch) {
          this.$message.warn(this.$t("未选择库位"));
          return;
        }
      }

      let pickingMaterialItems = [];
      for (let item of this.pickingMaterialItems) {
        if (item.picking_quantity > 0 && item.sub_batch) {
          pickingMaterialItems.push({
            picking_material: item.id,
            sub_batch: item.sub_batch,
            total_quantity: item.picking_quantity,
          });
        }
      }

      this.createLoading = true;
      pickingRecordCreate({
        picking_order: this.pickingOrderId,
        picking_record_material_items: pickingMaterialItems,
      })
        .then((data) => {
          this.$message.success(this.$t("创建成功"));
          this.$router.go(-1);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    removeMaterial(item, index) {
      this.pickingMaterialItems.splice(index, 1);
    },
    copyMaterial(item, index) {
      this.pickingMaterialItems.splice(index, 0, { ...item });
    },
    autoPicking(item, index) {
      let materialItems = [];
      let remainQuantity = item.picking_quantity;

      for (let subBatchItem of item.recommend_location_items) {
        if (remainQuantity > subBatchItem.remain_quantity) {
          materialItems.push({
            ...item,
            sub_batch: subBatchItem.id,
            location_number: subBatchItem.location_number,
            picking_quantity: subBatchItem.remain_quantity,
          });

          remainQuantity -= subBatchItem.remain_quantity;
        } else {
          materialItems.push({
            ...item,
            sub_batch: subBatchItem.id,
            location_number: subBatchItem.location_number,
            picking_quantity: remainQuantity,
          });

          break;
        }
      }
      this.pickingMaterialItems.splice(index, 1, ...materialItems);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped></style>
